















































import {
  defineComponent, PropType, ref, Ref, toRef, useContext
} from '@nuxtjs/composition-api';

import PictureItem from "~/components/Palmers/BaseComponents/Picture/PictureItem.vue";
import {VideoData, VimeoYoutube} from "~/modules/palmers/GraphQl/video/types";

export default defineComponent({
  name: 'VideoBlock',
  components: {
    PictureItem
  },
  props: {
    videoData: {
      type: Object as PropType<VideoData>,
      require: true
    },
    preview: {
      type: String,
      require: true
    },
    videoSources: {
      type: Array,
      require: true
    },
    previewImageType: {
      type: String,
      require: true
    },
    alt: {
      type: String,
      require: true
    },
    autoplayVideo: {
      type: Boolean,
      require: true,
      default: () => false
    },
    mute: {
      type: Boolean,
      require: true,
      default: () => false
    },
    controls: {
      type: Boolean,
      require: true,
      default: () => false
    },
    width: {
      type: Number,
      default: () => 300
    },
    source: {
      type: String,
      default: () => 'default'
    }
  },
  watch: {
    videoPlayed(newVal, oldVal) {
      const video = this.$refs.video as VimeoYoutube;

      if (this.videoProvider !== 'youtube') {
        if (newVal === true) {
          video?.play();
        }

        if (newVal === false && oldVal === true) {
          video?.pause();
        }
      } else {
        if (newVal === true) {
          video.player.playVideo();
        }

        if (newVal === false && oldVal === true) {
          video.player.pauseVideo();
        }
      }
    },
    videoMuted(newVal, oldVal) {
      if (this.videoProvider === 'external-video') {
        if (newVal === true) {
          (this.$refs.video as HTMLVideoElement).muted = true;
        }

        if (newVal === false && oldVal === true) {
          (this.$refs.video as HTMLVideoElement).muted = false;
        }
      } else if (this.videoProvider === 'vimeo') {
        if (newVal === true) {
          (this.$refs.video as VimeoYoutube).player.setMuted(true);
        }

        if (newVal === false && oldVal === true) {
          (this.$refs.video as VimeoYoutube).player.setMuted(false);
        }
      } else if (this.videoProvider === 'youtube') {
        if (newVal === true) {
          (this.$refs.video as VimeoYoutube).player.mute();
        }

        if (newVal === false && oldVal === true) {
          (this.$refs.video as VimeoYoutube).player.unMute();
        }
      }
    }
  },

  setup(props) {
    const videoMuted = ref(props.mute);
    const videoPlayed = ref(props.autoplayVideo);
    const autoplay = ref(props.autoplayVideo);
    const videoData = toRef(props, 'videoData');
    const videoProvider = ref((videoData.value as VideoData).provider);

    if ((videoData.value as VideoData).media_type === 'external-video') {
      videoProvider.value = (videoData.value as VideoData).media_type;
    }

    const {$vsf, $config} = useContext();

    return {
      magentoBaseUrl: $vsf.$magento.config.magentoBaseUrl as string,
      magentoMediaUrl: $config.magentoMediaUrl as string,
      videoMuted,
      videoPlayed,
      autoplay,
      videoProvider
    }
  },

  methods: {
    getSource(): string {
      if (this.source === 'default') {
        return this.magentoBaseUrl as string;
      }

      return this.magentoMediaUrl as string;
    },
    getUrl() {
      if ((this.preview as string).indexOf('http') > -1) {
        return this.preview;
      }
      const sourceFunction = this.getSource as () => string;

      const source = sourceFunction();

      return `${source}/${this.preview}`;
    },
    getProvider(): string {
      if ((this.videoData as VideoData).video_url.indexOf('vimeo') > 1) {
        return 'vimeo';
      }

      return 'youtube';
    },
    getVideoCode(url: string): string {
      const toUrl = new URL(url);
      return toUrl.searchParams.get('v');
    },
    getFrameUrl() {
      const videoCodeFunction = this.getVideoCode as (url: string) => string;
      const videoCode = videoCodeFunction((this.videoData as VideoData).video_url);

      let frameUrl = `https://www.youtube.com/embed/${videoCode}`;

      frameUrl += `?autoplay=${this.autoplay}&mute=${this.mute}&controls=${this.controls}&modestbranding=1&rel=0&loop=1&showinfo=0`;

      return frameUrl;
    },
    getYoutubeVideoId(url) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = url.match(regExp);
      return (match && match[7].length == 11) ? match[7] : false;
    },
    getVimeoFrameUrl() {
      const videoUrlArr = (this.videoData as VideoData).video_url.split('/');
      let frameUrl = `https://player.vimeo.com/video/${videoUrlArr[videoUrlArr.length - 1]}`;

      frameUrl += `?autoplay=${this.autoplay}&loop=1&autopause=0&muted=${this.mute}&background=1&w=380`

      return frameUrl;
    },
    playVideo() {
      this.autoplay = !this.autoplay;
      this.videoPlayed = !this.videoPlayed;
    },
    preloadVideo(videoElement: HTMLVideoElement, onPreload: Function = () => {
    }): void {
      if (!videoElement) {
        return;
      }

      let preloaded = false;

      videoElement.addEventListener('loadedmetadata', () => {
        videoElement.currentTime = 0;
      });

      videoElement.addEventListener('canplaythrough', () => {
        if (!preloaded) {
          preloaded = true;
          onPreload();
        }
      });

      videoElement.load();
    },
    muteVideo() {
      this.videoMuted = !this.videoMuted;
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.videoProvider) {
        const providerFunction = this.getProvider as () => string;
        this.videoProvider = providerFunction();
      }

      if (this.videoProvider === 'external-video') {
        const preloadFunction = this.preloadVideo as (videoElement: HTMLVideoElement, onPreload: Function) => void;
        preloadFunction(this.$refs.video as HTMLVideoElement, () => {
        });
      }
    })
  }
});
